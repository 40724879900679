import { srConfig } from '@config';
import { usePrefersReducedMotion } from '@hooks';
import { useStaticQuery, graphql } from 'gatsby';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import sr from '@utils/sr';
import React, { useState, useEffect, useRef } from 'react';
import { Icon } from '@components/icons';
import styled from 'styled-components';

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: clamp(24px, 5vw, var(--fz-heading));
  }

  .subtitle {
    text-align: left;
    width: 100%;
  }

  .packages-grid {
    ${({ theme }) => theme.mixins.resetList};
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 15px;
    position: relative;
    margin-top: 50px;
    width: 100%;

    @media (max-width: 1080px) {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
  }

  .more-button {
    ${({ theme }) => theme.mixins.button};
    margin: 80px auto 0;
  }
`;

const StyledPackage = styled.li`
  position: relative;
  cursor: default;
  transition: var(--transition);

  @media (prefers-reduced-motion: no-preference) {
    &:hover,
    &:focus-within {
      .package-inner {
        transform: translateY(-7px);
      }
    }
  }

  a {
    position: relative;
    z-index: 1;
  }

  .package-inner {
    ${({ theme }) => theme.mixins.boxShadow};
    ${({ theme }) => theme.mixins.flexBetween};
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    height: 100%;
    padding: 2rem 1.75rem;
    border-radius: var(--border-radius);
    background-color: var(--light-navy);
    transition: var(--transition);
  }

  .package-border {
    border: solid 2px var(--green-tint);
  }

  .package-top {
    ${({ theme }) => theme.mixins.flexBetween};
    margin-bottom: 35px;

    .folder {
      color: var(--green);
      svg {
        width: 40px;
        height: 40px;
      }
    }

    .package-links {
      display: flex;
      align-items: center;
      margin-right: -10px;
      color: var(--light-slate);

      a {
        ${({ theme }) => theme.mixins.flexCenter};
        padding: 5px 7px;

        &.external {
          svg {
            width: 22px;
            height: 22px;
            margin-top: -4px;
          }
        }

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .package-title {
    margin: 0 0 10px;
    color: var(--lightest-slate);
    font-size: var(--fz-xxl);

    a {
      position: static;

      &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
  }

  .package-description {
    color: var(--light-slate);
    font-size: 17px;

    a {
      ${({ theme }) => theme.mixins.inlineLink};
    }
  }

  .package-tech-list {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-grow: 1;
    //flex-wrap: wrap;
    padding: 0;
    margin: 20px 0 0 0;
    list-style: none;

    li {
      font-family: var(--font-mono);
      font-size: var(--fz-xxs);
      line-height: 1.75;
      margin: 0;

      &:not(:last-of-type) {
        margin-right: 15px;
      }
    }
  }
`;

const Packages = () => {
  const [showMore, setShowMore] = useState(false);
  const revealTitle = useRef(null);
  const revealArchiveLink = useRef(null);
  const revealPackages = useRef([]);
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();
  const data = useStaticQuery(graphql`
    query {
      packages: allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "/packages/" }
          frontmatter: { hideInPackages: { ne: true } }
        }
        sort: { fields: [frontmatter___priority], order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              title
              tech
              external
              border
            }
            html
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (prefersReducedMotion) return;
    sr.reveal(revealContainer.current, srConfig());
    sr.reveal(revealTitle.current, srConfig());
    sr.reveal(revealArchiveLink.current, srConfig());
    revealPackages.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 100)));
  }, []);

  const GRID_LIMIT = 3;
  const packages = data.packages.edges.filter(({ node }) => node);
  const firstLimited = packages.slice(0, GRID_LIMIT);
  const packagesToShow = showMore ? packages : firstLimited;

  const packageInner = node => {
    const { frontmatter, html } = node;
    const { external, title, tech, border } = frontmatter;

    return (
      <div className={'package-inner ' + (border ? 'package-border' : '')}>
        <header>
          <div className="package-top">
            <div className="folder">
              <Icon name="Logo" />
            </div>
            {/* <div className="package-links">
              {external && (
                <a
                  href={external}
                  aria-label="External Link"
                  className="external"
                  //target="_blank"
                  rel="noreferrer">
                  <Icon name="External" />
                </a>
              )}
            </div> */}
          </div>

          <h3 className="package-title">
            <a href={external} rel="noreferrer">
              {title}
            </a>
          </h3>

          <div className="package-description" dangerouslySetInnerHTML={{ __html: html }} />
        </header>

        <footer>
          {tech && (
            <ul className="package-tech-list">
              {tech.map((tech, i) => (
                <li key={i}>{tech}</li>
              ))}
            </ul>
          )}
        </footer>
      </div>
    );
  };

  return (
    <StyledSection id="packages" ref={revealContainer}>
      <h2 className="numbered-heading">Third Eye Opening</h2>
      {/* <p className="subtitle">All Packages are Donation based.</p> */}
      <ul className="packages-grid">
        {prefersReducedMotion ? (
          <>
            {packagesToShow &&
              packagesToShow.map(({ node }, i) => (
                <StyledPackage key={i}>{packageInner(node)}</StyledPackage>
              ))}
          </>
        ) : (
          <TransitionGroup component={null}>
            {packagesToShow &&
              packagesToShow.map(({ node }, i) => (
                <CSSTransition
                  key={i}
                  classNames="fadeup"
                  timeout={i >= GRID_LIMIT ? (i - GRID_LIMIT) * 300 : 300}
                  exit={false}>
                  <StyledPackage
                    key={i}
                    ref={el => (revealPackages.current[i] = el)}
                    style={{
                      transitionDelay: `${i >= GRID_LIMIT ? (i - GRID_LIMIT) * 100 : 0}ms`,
                    }}>
                    {packageInner(node)}
                  </StyledPackage>
                </CSSTransition>
              ))}
          </TransitionGroup>
        )}
      </ul>

      {packages.length > GRID_LIMIT && (
        <button className="more-button" onClick={() => setShowMore(!showMore)}>
          Show {showMore ? 'Less' : 'More'}
        </button>
      )}
    </StyledSection>
  );
};

export default Packages;
