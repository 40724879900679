import { IconLogo } from '@components/icons';
import { contactLinks } from '@config';
import { useOnClickOutside } from '@hooks';
import { KEY_CODES } from '@utils';
import { Link } from 'gatsby';
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

const StyledMenu = styled.div`
  display: block;
`;

const StyledSidebar = styled.aside`
  display: none;

  ${({ theme }) => theme.mixins.flexCenter};
  position: fixed;
  top: 0;
  bottom: 0;
  left: ${props => (props.left ? 'none' : 'calc(50vw - min(75vw, 400px) / 2)')};
  right: ${props => (!props.left ? 'none' : 'calc(50vw - min(75vw, 400px) / 2)')};
  padding: 50px 10px;
  width: min(75vw, 400px);
  height: 100vh;
  outline: 0;
  background-color: var(--light-navy);
  box-shadow: -10px 0px 30px -15px var(--navy-shadow);
  z-index: 12;
  transform: translateX(${props => (props.menuOpen ? 0 : props.left ? 100 : -100)}vw);
  visibility: ${props => (props.menuOpen ? 'visible' : 'hidden')};
  transition: var(--transition);

  nav {
    ${({ theme }) => theme.mixins.flexBetween};
    width: 100%;
    flex-direction: column;
    color: var(--lightest-slate);
    font-family: var(--font-mono);
    text-align: center;

    h4 {
      font-size: 0.8em;
      color: var(--green);
      position: fixed;
      top: 40px;
      left: 0;
      right: 0;
    }

    .logo {
      ${({ theme }) => theme.mixins.flexCenter};
      color: var(--green);
      width: 42px;
      height: 42px;
      position: fixed;
      bottom: 40px;

      svg {
        fill: none;
        transition: var(--transition);
        user-select: none;
      }
    }
  }

  ol {
    padding: 0;
    margin: 0;
    list-style: none;
    width: 100%;

    li {
      position: relative;
      margin: 0 auto 20px;
      counter-increment: item 1;
      font-size: clamp(var(--fz-sm), 4vw, var(--fz-lg));

      @media (max-width: 600px) {
        margin: 0 auto 10px;
      }

      &:before {
        content: '0' counter(item) '.';
        display: block;
        margin-bottom: 5px;
        color: var(--green);
        font-size: var(--fz-sm);
      }
    }

    a {
      ${({ theme }) => theme.mixins.link};
      width: 100%;
      padding: 3px 20px 20px;
    }
  }
`;

const Menu = ({ menuOpen, setMenuOpen }) => {
  const navRef = useRef(null);

  let menuFocusables;
  let firstFocusableEl;
  let lastFocusableEl;

  const setFocusables = () => {
    menuFocusables = Array.from(navRef.current.querySelectorAll('a'));
    firstFocusableEl = menuFocusables[0];
    lastFocusableEl = menuFocusables[menuFocusables.length - 1];
  };

  const handleBackwardTab = e => {
    if (document.activeElement === firstFocusableEl) {
      e.preventDefault();
      lastFocusableEl.focus();
    }
  };

  const handleForwardTab = e => {
    if (document.activeElement === lastFocusableEl) {
      e.preventDefault();
      firstFocusableEl.focus();
    }
  };

  const onKeyDown = e => {
    switch (e.key) {
      case KEY_CODES.ESCAPE:
      case KEY_CODES.ESCAPE_IE11: {
        setMenuOpen(false);
        break;
      }

      case KEY_CODES.TAB: {
        if (menuFocusables && menuFocusables.length === 1) {
          e.preventDefault();
          break;
        }
        if (e.shiftKey) {
          handleBackwardTab(e);
        } else {
          handleForwardTab(e);
        }
        break;
      }

      default: {
        break;
      }
    }
  };

  const onResize = e => {
    if (e.currentTarget.innerWidth > 768) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    window.addEventListener('resize', onResize);

    setFocusables();

    return () => {
      document.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const wrapperRef = useRef();
  useOnClickOutside(wrapperRef, () => setMenuOpen(false));

  const Logo = (
    <div className="logo" tabIndex="-1">
      <IconLogo />
    </div>
  );

  return (
    <StyledMenu>
      <Helmet>
        <body className={menuOpen ? 'blur' : ''} />
      </Helmet>

      <div ref={wrapperRef}>
        <StyledSidebar menuOpen={menuOpen} aria-hidden={!menuOpen} tabIndex={menuOpen ? 1 : -1}>
          <nav ref={navRef}>
            {contactLinks && (
              <ol>
                {contactLinks.map(({ url, name }, i) => (
                  <li key={i}>
                    <a
                      href={url}
                      onClick={() => setMenuOpen(false)}
                      target={'_blank'}
                      rel="noopener noreferrer">
                      {name}
                    </a>
                  </li>
                ))}
              </ol>
            )}
          </nav>
        </StyledSidebar>
        <StyledSidebar
          left
          menuOpen={menuOpen}
          aria-hidden={!menuOpen}
          tabIndex={menuOpen ? 1 : -1}>
          <nav ref={navRef}>
            <h4>Text me on:</h4>
            {contactLinks && (
              <ol>
                {contactLinks.map(({ url, name }, i) => (
                  <li key={i}>
                    <a
                      href={url}
                      onClick={() => setMenuOpen(false)}
                      target={'_blank'}
                      rel="noopener noreferrer">
                      {name}
                    </a>
                  </li>
                ))}
              </ol>
            )}
            {Logo}
          </nav>
        </StyledSidebar>
      </div>
    </StyledMenu>
  );
};

export default Menu;
